import styled from "@emotion/styled";
import { StyledWithThemeProps } from "utils/types";
import typography from 'styles/typography';
import { Link } from "react-router-dom";

export const Wrapper = styled.div<StyledWithThemeProps>`
    background: ${({ theme }) => theme.colors.global.genAIBrand};
    height: 100%; 
    width: 100%; 
    padding: 48px; 
    overflow: hidden;
`

export const TitleWrapper = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    gap: 40px; 
`
export const PageTitle = styled.div<StyledWithThemeProps>`
    text-align: center;
    font-family: ${typography.primaryFont};
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text.primary};

`
export const SubTitle = styled.div<StyledWithThemeProps>`
    font-family: ${typography.primaryFont};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${({ theme }) => theme.colors.text.primary};
    display: flex; 
    align-items: center; 
    text-align: center; 
`
export const GenAILink = styled(Link)<StyledWithThemeProps>`
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.text.primary};
    color: ${({ theme }) => theme.colors.text.white};
    width: 270px;
    height: 60px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center; 
    align-items: center; 
    display: flex; 
    justify-content: center; 
`