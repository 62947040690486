import styled from "@emotion/styled";

export const CenterGrid = styled.div`
    display: flex; 
    flex-direction: column; 
    padding: 60px 40px 0px 40px; 
    height: 100vh; 
    width: 100%; 
    gap: 15px; 

`
export const RowGrid = styled.div`
    display: flex; 
    flex-direction: row; 
    overflow: hidden;
`