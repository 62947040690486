import styled from "@emotion/styled";

interface OfferGeneratorItemContentStyledProps  {
    selected: boolean
}

export const OfferGeneratorItemContent = styled.button<OfferGeneratorItemContentStyledProps>`

    ${({selected}) => selected ? 'border: 2px solid #ED5500;  background: #FFF3F3;': 'border: 2px solid #D8D8D8;     background: #FFF;'}
    display: flex; 
    flex-direction: column; 
    gap: 5px; 
    width: 325px;
    border-radius: 7.561px;
    padding: 15px 20px 15px 20px; 
    cursor: pointer;
`

export const ItemTitle = styled.h3`
    color: #292929;
    font-size: 21px
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`