import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import { StyledWithThemeProps } from "utils/types"


export const SideBarWrapper = styled.nav<StyledWithThemeProps>`
    background: ${({ theme }) => theme.colors.global.genAIBrand};
    height: 100vh; 
    width: 250px; 
    display: flex; 
    flex-direction: column; 
    gap: 30px; 
    padding-top: 60px; 
`

export const NavItem = styled(Link)<StyledWithThemeProps & { selected?: boolean, isSub?: boolean}>`
    background: ${({ selected, theme }) => selected &&  theme.colors.text.primary};
    color: ${({ selected, theme }) => selected ? theme.colors.text.white :  theme.colors.text.primary};
    padding : 15px; 
    width: 220px; 
    border-radius: 0px 60px 60px 0px; 
    padding-left: 50px;
    font-size: ${({ isSub }) => isSub ? '14px' :  '16px'};
    font-weight: ${({ isSub }) => isSub ? '400' :  '600'};
`

export const IconWrapper = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center; 
`

export const NavItemsWrapper = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center;
    gap: 10px; 
    width: 100%; 
    padding-right: 30px; 
    position: sticky; 
    top: 150px; 
`

export const SideNavDivider = styled.div`
    height: 1px;
    background: #292929;
    width: 100%; 

`