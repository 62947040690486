import styled from "@emotion/styled";
import typography from "styles/typography";
import { StyledWithThemeProps } from "utils/types";



export const MobileViewWrapper = styled.div`
    border-radius: 40px;
    border: 9px solid #292929;
    height: 700px; 
    background: #FFF;
    padding: 25px; 
    flex-direction: column; 
    display: flex; 
    font-size: 18px;
    text-align: left; 
    justify-content: space-evenly; 
    overflow-y: hidden; 
 
`

export const MobileViewTitle = styled.p<StyledWithThemeProps & { selectedOffer?: boolean}>`
     font-family: ${typography.primaryFont};
     font-size: 24px;
     font-weight: 600;
     line-height: 24px;
     color: ${({ theme, selectedOffer }) => selectedOffer ? theme.colors.text.primary : theme.colors.text.disabled };

`

export const MobileViewDescription = styled.p<StyledWithThemeProps>`
    font-family: ${typography.primaryFont};
     font-size: 12px;
     font-weight: 400;
     color: ${({ theme }) =>  theme.colors.text.primary };
     line-height: normal;

`


export const MobileViewMenu = styled.div`
    display: flex; 
    flex-direction: column; 
    gap: 5px; 
    width: 100%; 
`

export const MenuTitle = styled.h1`
    font-family: ${typography.primaryFont};
    font-size: 16px;
    font-weight: 600;
    color: #292929;
    line-height: normal;
`

export const MenuOption = styled.button`
    display: flex; 
    flex-direction: row; 
    border-radius: 4px;
    border: 1px solid #959595;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    background: #FFF;
    padding: 10px; 
    justify-content: space-between; 
    align-items: center; 
`

export const MenuOptionFlex = styled.div`
    display: flex; 
    flex-direction: row; 
    gap: 5px; 
    font-family: ${typography.primaryFont};
    font-weight: 600; 
    font-size: 12px;
    justify-content: flex-start; 
    align-items: center; 
`

export const TitleWrapper = styled.div`
    display: flex; 
    gap: 2px; 
    flex-direction: column; 

`