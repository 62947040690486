import { promptItem } from "pages/genAI/genAI/genAI";
import { ItemTitle, OfferGeneratorItemContent } from "./OfferGeneratorItemButton.style";


interface OfferGeneratorItemButtonProps {
    promptItem: promptItem,
    onResponseItemClick: (item: promptItem) => void; 
    selectedOffer?: promptItem,
}

const OfferGeneratorItemButton = ({promptItem, onResponseItemClick, selectedOffer } : OfferGeneratorItemButtonProps) => {

        const handleClick = () => {
            onResponseItemClick(promptItem)
        }
        return <>
            <OfferGeneratorItemContent onClick={handleClick} 
                selected={selectedOffer && promptItem.id === selectedOffer.id}
                >
            <ItemTitle>{promptItem.response.versions[0].translationsMap['en-US'].title}</ItemTitle>
            </OfferGeneratorItemContent>
            
        </>

    
}

export default OfferGeneratorItemButton; 