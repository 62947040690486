import styled from "@emotion/styled";
import typography from "styles/typography";

export const ChatWrapper = styled.div`
    box-sizing: border-box;
    border-radius: 30px 30px 0px 0px;
    display: flex; 
    flex-direction: column; 
    overflow-y: scroll; 
    width: 100%; 
    margin-bottom: 50px; 
`

export const PromptWrapper = styled.div`
    background: #292929;
    color: white; 
    display: flex; 
    flex-direction: row; 
    font-size: 18px;
    padding: 18px; 
    justify-content: space-between; 
    align-items: center; 
    gap: 20px; 

`

export const ResponseWrapper = styled.div`
    background: #F8F7F7;
    display: flex; 
    flex-direction: column;  
    padding: 20px; 
    gap: 10px; 

`

export const ResponseSummary = styled.div`
    display: flex; 
    flex-direction: column; 

`

export const TextWrapper = styled.div`
    display: flex; 
    flex-direction: row; 
    gap: 15px; 
    align-items:center; 
    width: 100%; 
`

export const EditWrapper = styled.div`
    display: flex; 
    cursor: pointer; 
    gap: 10px; 
`

export const EditPromptInput = styled.input`
    font-family: ${typography.primaryFont};
    font-size: 20px;
    font-style: normal;
    width: 100%;
`

export const RatingIconsWrapper = styled.div`
    display: flex; 
    flex-direction: row; 
    gap: 8px; 
    padding-left: 20px; 
`

export const ResponseTitleWrapper  = styled.div`
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
`