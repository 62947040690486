import { Icon } from "components/shared/icon";
import { promptItem, getRandomInt, makeGenAICall } from "pages/genAI/genAI/genAI";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { IconWrapper, StyledInput, StyledInputWithIconWrapper } from "./inputWithIcon.style";
import { resetFormValues } from "app/slices/offerDetails";
import { store } from "app/store";
import { addGeneratedOffer, setIsFromOfferDetails, setSelectedOffer, setGeneratedOfferImage } from "app/slices/offerGenerator";
import { defaultTheme } from "styles/themes/defaultTheme";
import { LoaderColor } from "components/shared/loader/Loader.consts";
import { CircleLoader } from "components/shared/circleLoader/CircleLoader";

interface InputWithIconProps {
    placeholder: string,
    name: string; 
}

const InputWithIcon = ({placeholder, name} : InputWithIconProps) => {

    const [inputValue, setInputValue] = useState<string>(''); 

    const handleInputChange = (event: ChangeEvent) => {
        let value = (event.target as HTMLInputElement).value;
        setInputValue(value);
    }

    const addOffer = ({ prompt ,response, id } : promptItem ) => {
        const newPrompt = { prompt, response, id};
        store.dispatch(addGeneratedOffer(newPrompt));
    }

    const [isLoading, setIsLoading] = useState<boolean>(false); 
    const [error, setError] = useState(null)

    useEffect(() => {
        store.dispatch(resetFormValues()); 
   
    }, [])

    // Generate from prompt only
    const handleSubmit = async () => {
        setError(null)
        store.dispatch(setSelectedOffer(null)); 
        
       const inputBody = {
            prompt: inputValue,  
        }
         // length of prompt > 5 
        if (inputValue !== '' && inputValue.split(' ').length > 3) {
            store.dispatch(setIsFromOfferDetails(false)); 
            setIsLoading(true); 
            const { response, error } = await makeGenAICall(inputBody); 
            if (response) {
                let promptItem = {prompt: inputValue, response: response.data, id: `${inputValue}${getRandomInt(1,100000)}`}
                addOffer(promptItem); 
                setInputValue(''); 
            }
            else if (error) {
                setError(`Error generating offer:  ${error}`); 
            }
            setIsLoading(false);
        }
        else {
            setError('Error generating offer: prompt length must be greater than 3 words'); 
        }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'enter') {
            handleSubmit(); 
        }
    }

    return <>
    <StyledInputWithIconWrapper>
        <StyledInput
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            type="text"
            name={name}
            >
        </StyledInput>
        {isLoading ? <CircleLoader color={LoaderColor.Secondary} /> : <IconWrapper onClick = {handleSubmit} onKeyDown={handleKeyDown}>
            <Icon name='submitPrompt' width={22} height={22}/>
        </IconWrapper>}
    </StyledInputWithIconWrapper>
    {error && <div style={{color: defaultTheme.colors.global.error}}>{error}</div>}
    </>
}

export default InputWithIcon