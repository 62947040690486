import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography, { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import { ButtonText } from '../button';
import Error from '../error/Error';
import { AdornmentPosition, StyleTextFieldProps } from './TextField.consts';

export const StyledContainer = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  align-items: ${({ labelIsHorizontal }) => !labelIsHorizontal && 'center'};
  width: inherit;
  ${({isOfferDetailsForm}) => isOfferDetailsForm && `
    gap: 8px; 
`}
`;

export const InputContainer = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  background-color: ${({ theme }) =>  theme.colors.global.background};
  ${({isOfferDetailsForm}) => isOfferDetailsForm && `
      border-radius: 5px;
      border: 1px solid #D8D8D8;
      padding: 3.5px; 
  `}

`;

export const StyledLabel = styled.label<StyleTextFieldProps & { disabled: boolean }>`
  font-family: Speedee;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.17px;
  text-align: left;
  color: ${ ({disabled}) => disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary};
  display: flex;
  align-items: start;
  margin-right: ${typography.unit * 3}px;
  white-space: nowrap;
  ${({isOfferDetailsForm, theme}) => isOfferDetailsForm && `
    color: ${theme && theme.colors.text.primary}
    font-family: ${typography.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `}
`;

export const StyledError = styled(Error)`
  position: absolute;
  margin-top: 32px;
  margin-left: ${typography.unit / 2}px;
  white-space: nowrap;
`;

export const InputWrapper = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: ${({ adornmentPosition }) => (adornmentPosition === AdornmentPosition.Start ? 'row' : 'row-reverse')};
  align-items: center;
  border-bottom: 1px solid
    ${({ theme, error }) =>
      error ? newGlobalTheme.colors.global.error : newGlobalTheme.colors.global.checkboxBorderColor};
  color: ${({ theme }) => theme.colors.text.primary};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  font-size: 30px; 
  border-bottom: ${({isOfferDetailsForm}) => isOfferDetailsForm && 'none;'}
  &:hover {
    border-bottom: 1px solid
      ${({ theme, disabled, error }) =>
        disabled ? theme.colors.global.inputDisabled : error ? theme.colors.global.error : theme.colors.text.disabled};
    border-bottom: ${({isOfferDetailsForm}) => isOfferDetailsForm && 'none;'}
  }

  &:focus-within {
    border-bottom: 1px solid
${({ theme, error }) => (error ? theme.colors.global.error : newGlobalTheme.colors.text.input)};
border-bottom: ${({isOfferDetailsForm}) => isOfferDetailsForm && 'none;'}
  }
`;

export const StyledInput = styled.input<StyleTextFieldProps>`
  border: none;
  background: none;
  outline: none;
  font-size: ${typography.fontSize}px;
  font-family: ${newTypography.primaryFont};
  flex: 1;
  color: ${ ({disabled}) => disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary};
  height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &::placeholder {
    color: ${ ({disabled}) => disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.global.inputDisabledSlide};;
  }
`;

export const StyledChildren = styled.div`
  display: flex;
`;

export const StyledViewButton = styled(ButtonText)`
  position: absolute;
  right: ${typography.unit * 4}px;

  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;
