import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { selectionItem } from 'pages/genAI/offerDetails/OfferDetails';

export enum OfferDetailsStateID {
  OfferDetailsPage = 'offerDetailsPage',
}

export type OfferDetailsKeys =   'offerTitle' | 'selectedModel' | 'discountAmount' | "templateType" | 'parentOfferType' | 'offerCode' | 'promotionType' | "childOfferType"

export interface OfferDetailsFilters {
    offerTitle?: string;
    selectedModel?: selectionItem; 
    discountAmount?: string; 
    templateType?: selectionItem; 
    parentOfferType?: selectionItem;
    offerCode?: selectionItem;
    promotionType?: selectionItem;
    childOfferType?: selectionItem;
}

export interface OfferDetails {
  formValues: 
    OfferDetailsFilters
  
}

const defaultValues : OfferDetailsFilters = 
{
  offerTitle: null,
  selectedModel: null,
  discountAmount: null,
  templateType: null,
  parentOfferType: null,
  offerCode:  null,
  promotionType: null,
  childOfferType: null,
}



export const initialState : OfferDetails = {
  formValues: {
      offerTitle: null,
      selectedModel: null,
      discountAmount: null,
      templateType: null,
      parentOfferType: null,
      offerCode:  null,
      promotionType: null,
      childOfferType: null,
  }

};


export const offerDetailsSlice = createSlice({
  name: 'offerDetails',
  initialState,
  reducers: {
    setFormValue(state, action) {
      state.formValues = {
        ...state.formValues,
        [action.payload.key]: action.payload.value,
      };
    },
    resetFormValues(state) {
      state.formValues = defaultValues; 
    }
  },

});

export const offerDetails = (state: RootState) => state.offerDetails;
export const { setFormValue, resetFormValues } = offerDetailsSlice.actions;

export default offerDetailsSlice.reducer;
