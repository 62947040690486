
import { promptItem } from "pages/genAI/genAI/genAI";
import { predictedMargin } from "pages/genAI/offerDetails/OfferDetails";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import typography from "styles/typography";
import { StyledWithThemeProps } from "utils/types";


export const OfferPreviewWrapper = styled.div`
     background: #F5F5F5;
     width: 550px; 
     display: flex; 
     flex-direction: column; 
     gap: 30px; 
     padding: 60px 30px 40px 30px; 
     justify-content: center;
     overflow: hidden; 
     box-sizing: content-box; 
     

`

export const OfferPreviewTitle = styled.p<StyledWithThemeProps>`
     font-family: ${typography.primaryFont};
     font-size: 24px;
     font-weight: 700;
     line-height: normal;
     color: ${({ theme }) =>  theme.colors.text.primary};

`
interface GenAIButtonProps {
     selectedOffer?: promptItem, 
     predictedMargin?: number, 
     fromPage?: 'details' | 'generator'
}

export const RegenerateBtn = styled.button<GenAIButtonProps>`

     ${({selectedOffer, predictedMargin}) => (selectedOffer || predictedMargin) ? 'border: 3px solid #DB0006;  color: #DB0006; background-color: white; ' : 'border: 3px solid #9D9EA1;  color: #9D9EA1;'}
     text-align: center;
     font-family: ${typography.primaryFont};
     font-size: 16px;
     font-style: normal;
     font-weight: 700;
     border-radius: 6px;
     padding: 8px; 
     ${({selectedOffer, predictedMargin}) => (selectedOffer || predictedMargin) && 'cursor: pointer;'}
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
    
`

export const RegenerateLink = styled(Link)<GenAIButtonProps>`
${({fromPage, selectedOffer, predictedMargin}) => fromPage === 'generator' ? selectedOffer ? 'border: 3px solid #DB0006;  color: #DB0006; background-color: white; ' : 'border: 3px solid #9D9EA1;  color: #9D9EA1;' : predictedMargin ? 'border: 3px solid #DB0006;  color: #DB0006; background: white; ' : 'border: 3px solid #9D9EA1;  color: #9D9EA1;'}
     text-align: center;
     font-family: ${typography.primaryFont};
     font-size: 16px;
     font-style: normal;
     font-weight: 700;
     cursor: default; 
     ${({predictedMargin}) => (predictedMargin) && 'cursor: pointer;'}

     border-radius: 6px;
     padding: 8px; 
`

export const PublishBtn = styled.button<GenAIButtonProps>`
     ${({selectedOffer}) => selectedOffer ? 'background: #DB0006;  border: 3px solid #DB0006;' : 'background: #9D9EA1; border: 3px solid #9D9EA1;'}
     border-radius: 6px; 
     color: #FFF;
     text-align: center;
     font-family: ${typography.primaryFont};
     font-size: 16px;
     font-style: normal;
     font-weight: 700;
     padding: 8px; 
     ${({selectedOffer}) => selectedOffer && 'cursor: pointer;'}



`

export const PredictBtn = styled.button<GenAIButtonProps>`
     background: #DB0006;  
     border: 3px solid #DB0006;
     border-radius: 6px;
     color: #FFF;
     text-align: center;
     font-family: ${typography.primaryFont};
     font-size: 16px;
     font-style: normal;
     font-weight: 700;
     width: 187px; 
     padding: 8px; 
     cursor: pointer; 
`

export const ButtonWrapper = styled.div`
     display: flex; 
     flex-direction: column;
     gap: 12px; 
`

export const PredictedMarginBox = styled.div`
     border-radius: 6px;
     border: 3px solid #DB0006;
     background: #FFF;
     width: 100%;
     height: 250px;
     display: flex; 
     flex-direction: column; 
     padding 30px; 
     justify-content: center; 
     gap: 30px; 
`

export const PredictedMarginTitle = styled.div`
     color: #292929;
     text-align: center;
     font-family: ${typography.primaryFont};
     font-size: 14px;
     font-style: italic;
     font-weight: 700;
     line-height: 15px; 
`

export const PredictedMargin = styled.div`
     color: #292929;
     text-align: center;
     font-family: ${typography.primaryFont};
     font-size: 45px;
     font-style: normal;
     font-weight: 300;
     line-height: normal;
`


