

import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TextField from 'components/shared/textField/TextField';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'app/store';
import { ChildOfferType, ModelTypes, OfferPromotionType, OfferTemplates, OfferTypeCode, ParentOfferTypes } from 'utils/types/offers';
import LargePageTitle from '../components/LargePageTitle/LargePageTitle';
import NavSideBar from '../components/navSideBar/NavSideBar';
import OfferPreview from '../components/offerPreview/OfferPreview';
import { PredictBtn } from '../components/offerPreview/OfferPreview.style';
import { navItems, subNavItems } from '../genAI/genAI';
import { CenterGrid, RowGrid } from '../genAI/genAI.style';
import { GridColumn, GridWrapper, ModelSelectBox, ModelSelection, OfferSpecifics, OfferSpecificsTitle, PageSubtitle } from './OfferDetails.style';
import { offerDetails as offerDetailsSlice, setFormValue, resetFormValues, OfferDetailsKeys } from 'app/slices/offerDetails';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';

export interface selectionItem {
    id: number, 
    name: string,
    requestCode: string,
}

export interface predictedMargin {
    data: {
        prediction: number[]
    }
}
const OfferDetails = () => {

    const { formValues } = useSelector(offerDetailsSlice);
    const [predictedMargin, setPredictedMargin] = useState<number>(null)
    const [isLoading, setIsloading] = useState<boolean>(false); 

    useEffect(() => {
        store.dispatch(resetFormValues()); 
    }, [])

    useEffect(() => {
      
    }, [formValues.selectedModel])

    const [error, setError] = useState<string>(null)
    const onClick = async () => {
        setError(null); 
            setIsloading(true);
            console.log(formValues); 
            const inputData = {
                model_name: formValues.selectedModel.requestCode, 
                prediction_data : [
                {
                  discount_amount: formValues.discountAmount,
                  offer_type: formValues.childOfferType.name.split('(')[0].trim(),
                  offer_type_lvl_0: formValues.parentOfferType.name,
                  offr_typ_cd: formValues.offerCode.name,
                  digl_offr_prmo_typ_lcl_ds: formValues.promotionType.name,
                  digl_offr_prmo_tplt_typ_ds: formValues.templateType.name,
                  digl_offr_prmo_strt_lcl_ts: "2023-06-01T04:00:00.000Z",
                  digl_offr_prmo_end_lcl_ts: "2023-06-21T04:00:00.000Z",
                  digl_offr_prmo_titl_tx: formValues.offerTitle
                },
              ]};
            await fetch("https://52.87.195.230.nip.io/predict", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(inputData),
              })
                .then((response) => response.json())
                .then((data) => {
                    setPredictedMargin(parseFloat(data.prediction[0].toFixed(2)));
                    setIsloading(false); 
                })
                .catch((error) => {
                  setError(`Error making the prediction request: ${error}`); 
                  setIsloading(false); 
                });
      
    }
   
    return (
        <PageContainer>
            <RowGrid>
            <NavSideBar navBarItems={navItems} subNavItems={subNavItems}/>
            <CenterGrid>
                <LargePageTitle text='Offer Details'/>
                <PageSubtitle>
                    Start by selecting the AI model you would like to use. Then select the specifics of the offer and click “Predict” and we’ll get to work!
                </PageSubtitle>
                <ModelSelection>
                   <ModelSelectBox
                        labelIsHorizontal={false}
                        label={'Model'}
                        items={Object.values(ModelTypes)}
                        onChange={(item: any) => store.dispatch(setFormValue({key: 'selectedModel', value: item} ))}
                        isModelSelection
                        version='offer-form'
                        containerGap={8}
                    />
                </ModelSelection>
                {error && <div>{error}</div>}
                <OfferSpecifics>
                    <OfferSpecificsTitle>
                        Offer Specifics
                    </OfferSpecificsTitle>
                    <GridWrapper>
                    <GridColumn>
                    <TextField
                        name={'Discount Amount ($)'} label={'Discount Amount ($)'}
                        labelIsHorizontal
                        onChange={(item: any) => store.dispatch(setFormValue({key: 'discountAmount', value: item.target.value} ))}
                        isOfferDetailsForm
             
                        />
                    <NewSelectbox 
                        label={'Offer Promotion Template'}
                        labelIsHorizontal
                        isOfferDetailsForm
                        onChange={(item: any) => store.dispatch(setFormValue({key: 'templateType', value: item} ))}
                        items={Object.values(OfferTemplates)}
                        selectHeight={36}
                        containerGap={8}
                    />
                      <NewSelectbox 
                        label={'Parent Offer Type'}
                        labelIsHorizontal
                        isOfferDetailsForm
                        onChange={(item: any) => store.dispatch(setFormValue({key: 'parentOfferType', value: item} ))}
                        items={Object.values(ParentOfferTypes)}
                        selectHeight={36}
                        containerGap={8}
                    />

                    </GridColumn>
                    <GridColumn>
                    <NewSelectbox 
                        label={'Offer Type Code'}
                        labelIsHorizontal
                        isOfferDetailsForm
                        onChange={(item: any) => store.dispatch(setFormValue({key: 'offerCode', value: item} ))}
                        items={Object.values(OfferTypeCode)}
                        selectHeight={36}
                        containerGap={8}
                    />
                     <NewSelectbox 
                        label={'Offer Promotion Type'}
                        labelIsHorizontal
                        isOfferDetailsForm
                        onChange={(item: any) => store.dispatch(setFormValue({key: 'promotionType', value: item} ))}
                        items={Object.values(OfferPromotionType)}
                        selectHeight={36}
                        containerGap={8}
                    
                    />
                      <NewSelectbox 
                        label={'Child Offer Type'}
                        labelIsHorizontal
                        isOfferDetailsForm
                        onChange={(item: any) => store.dispatch(setFormValue({key: 'childOfferType', value: item} ))}
                        items={Object.values(ChildOfferType)}
                        selectHeight={36}
                        containerGap={8}
                    
                    />
                    </GridColumn>
                    </GridWrapper>
                       <TextField
                        name={'Offer Title Text'} 
                        label={'Offer Title Text'}
                        labelIsHorizontal
                        onChange={(item: any) => store.dispatch(setFormValue({key: 'offerTitle', value: item.target.value} ))}
                        isOfferDetailsForm
                        />
                <PredictBtn
                     onClick={onClick}>
                    Predict
                </PredictBtn>
                </OfferSpecifics>
            </CenterGrid>
            <OfferPreview fromPage ='details' predictedMargin={predictedMargin} isLoading={isLoading}/>
        </RowGrid>
        </PageContainer>
    )
}

export default OfferDetails;