import PageContainer from "pages/shared/pageContainer/PageContainer";
import LargePageTitle from "../components/LargePageTitle/LargePageTitle";
import NavSideBar from "../components/navSideBar/NavSideBar";
import { navItems, subNavItems } from "../genAI/genAI";
import { RowGrid, CenterGrid } from "../genAI/genAI.style";
import { CardItems, CardTitle, CardWrapper, CenterGridVertical, HorizontalSection, LineChartTitle, LineChartWrapper, PieChartLabel, PieChartWrapper, SubGrid, SubRow } from "./MeasurementsDashboard.style";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line
} from "recharts";


// Mock data for the charts
const data = [
    { name: "Mobile App", value: 40, color: "#ffbc0d" },
    { name: "Desktop", value: 25, color: "#DB0006" },
    { name: "Display Ad", value: 20, color: "green" },
    { name: "Direct Search", value: 15, color: "black" },
  ];
  
  const redemptionData = [
    { day: "Day 1", redemptions: 20 },
    { day: "Day 2", redemptions: 15 },
    { day: "Day 3", redemptions: 10 },
    { day: "Day 4", redemptions: 18 },
    { day: "Day 5", redemptions: 12 },
    { day: "Day 6", redemptions: 8 },
    { day: "Day 7", redemptions: 14 },
  ];

const MeasurementsDashboard = () => {
    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let currentDate = `${month}/${day}/${year}`;


    type CardProps = {
        title: string, 
        value: string, 
        percentage?: number, 
        previous: string, 
    }
    const Card = ({ title, value, previous, percentage } : CardProps) => {
        return <CardWrapper>
            <CardTitle>
                {title}
            </CardTitle>
            <CardItems percentage={percentage}>
              <h4 style={{ fontSize: 38, fontWeight: 600, marginBottom: 1 }}>
                {value}
              </h4>
            {percentage && (
              <div style={{ fontSize: 18, fontWeight: 200, marginBottom: 1, color: percentage > 0 ? "green" : "red" }}>
            {percentage > 0 ? `↑ ${percentage}%` : `↓ ${percentage}%`}
          </div>
        )}
         <div >{previous}</div>
              </CardItems>
        </CardWrapper>
    }

    const RADIAN = Math.PI / 180;

  type props = {
      cx: any,
      cy: any,
      midAngle: any,
      innerRadius: any,
      outerRadius: any,
      percent: any,
  }
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
} : props) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontWeight={600}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderLegend = (props : any) => {
  const { payload } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        height: 50,
        alignContent: "center",
      }}
    >
      {payload.map((entry: any, index: any) => (
        <div
          key={`item-${index}`}
          style={{
            display: "flex",
            alignItems: "start",
            marginBottom: 5,
            marginRight: 40,
          }}
        >
          <div
            style={{
              width: 20,
              height: 20,
              borderRadius: 50,
              backgroundColor: entry.color,
              marginRight: 5,
            }}
          />
          <span>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

    return (
        <PageContainer>
            <RowGrid>
            <NavSideBar navBarItems={navItems} subNavItems={subNavItems}/>
                <CenterGridVertical>
                <LargePageTitle text='Measurement Dashboard' />
                <div style={{paddingBottom: '20px'}}>
                    <p>Last Updated {currentDate}</p>
                </div>
            <HorizontalSection>
                <SubGrid>  
                    <SubRow>
                    <Card
                    title="Redemption Rate"
                    value="30%"
                    percentage={12}
                    previous="previous 30 days"
                  />
                   <Card
                    title="Margin $"
                    value="$-1073.92"
                    previous="previous 30 days"
                  />

                    </SubRow>
                    <SubRow>
                    <Card
                    title="Total Offers Amount"
                    value="$10,356"
                    previous="previous 30 days"
                  />
                   <Card
                    title="Total Active Offers"
                    value="40"
                    previous="previous 30 days"
                  />
                    </SubRow>
                </SubGrid>
                <PieChartWrapper>
                <PieChartLabel>Offers Viewed By Channel</PieChartLabel>
                <PieChart width={400} height={400}>
                      <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        outerRadius={150}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        fill="red"
                        dataKey="value"
                      >
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Legend content={renderLegend} />
                    </PieChart>
                </PieChartWrapper>
                </HorizontalSection>
                <LineChartWrapper>
                  <LineChartTitle>Total Redemptions (daily) </LineChartTitle>
                  <LineChart
                      width={900}
                      height={300}
                      data={redemptionData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 9" />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      <Line
                        dataKey="redemptions"
                        stroke="#ffbc0d"
                        strokeWidth={2}
                        dot={{ r: 5 }}
                      />
                    </LineChart>
                </LineChartWrapper>
                </CenterGridVertical>
            </RowGrid>
        </PageContainer>

    )

}

export default MeasurementsDashboard; 