
import { getTabByPath } from "components/header/Header.consts";
import { Icon } from "components/shared/icon";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { defaultTheme } from "styles/themes/defaultTheme";
import { IconWrapper, NavItem, NavItemsWrapper, SideBarWrapper, SideNavDivider } from "./NavSideBar.style";


interface NavItemType {
    title: string,
    url: string,
    id: string
}

interface NavBarProps {
    navBarItems: NavItemType[];
    subNavItems?: NavItemType[];
  }

const NavSideBar = ({ navBarItems, subNavItems }: NavBarProps) => {
    const history = useHistory();
    const [selectedTabId, setSelectedTabId] = useState<string>(`tab-${getTabByPath(history.location.pathname)}`);

    useEffect(() => {
        setSelectedTabId(`tab-${getTabByPath(history.location.pathname)}`);
      }, [history.location]);


    return <>

        <SideBarWrapper theme={defaultTheme}>
            <IconWrapper>
                <Icon name='mcdSmile' width={47} height={47}/>
            </IconWrapper>
            <NavItemsWrapper>
                {navBarItems.map(item => {
                        return <NavItem
                                to={item.url}
                                theme={defaultTheme}
                                selected={selectedTabId === item.id}>
                                {item.title}
                        </NavItem>
                })}
            </NavItemsWrapper> 
            {subNavItems && 
                <>
                    <SideNavDivider/>
                    <NavItemsWrapper>
                    {subNavItems.map(item => {
                        return <NavItem
                                to={item.url}
                                isSub
                                theme={defaultTheme}
                                selected={selectedTabId === item.id}>
                                {item.title}
                        </NavItem>
                })}

                    </NavItemsWrapper>
                </>
            
            }
                
        </SideBarWrapper>
    </>
    

}

export default NavSideBar; 