import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { promptItem } from 'pages/genAI/genAI/genAI';


export interface OfferGenerator {
    generatedOffers?: promptItem[];
    selectedPrompt?: string; 
    selectedResponse?: {title: string, img?: string, [key: string]: any };
    selectedOffer?: promptItem; 
    isFromOfferDetails?: boolean;
    generatedOfferImage: {[key: string]: any };
}

const defaultValues : OfferGenerator = 
{
    generatedOffers: [],
    selectedPrompt: null, 
    selectedResponse: null,
    selectedOffer: null,
    isFromOfferDetails: false, 
    generatedOfferImage: null,
}

export const initialState : OfferGenerator = defaultValues;

export const offerGeneratorSlice = createSlice({
  name: 'offerGenerator',
  initialState,
  reducers: {
    setSelectedOffer(state, action) {
      state.selectedOffer = action.payload; 
    },
    addGeneratedOffer(state, action) {
        state.generatedOffers = [...state.generatedOffers, action.payload]
    }, 
    setSelectedPrompt(state, action) {
        state.selectedPrompt = (action.payload);
    }, 
    setSelectedResponse(state, action) {
        state.selectedResponse = (action.payload);
    },
    setIsFromOfferDetails(state, action) {
        state.isFromOfferDetails = (action.payload); 
    }, 
    updateExistingOffer(state, action) {
      state.generatedOffers.map((offer, index) => {
          if (offer.id === action.payload.id) {
            state.generatedOffers[index].prompt = action.payload.prompt; 
            if (action.payload.response) {
              state.generatedOffers[index].response = action.payload.response; 
            }
          }
      })
    }, 
    setGeneratedOfferImage(state, action) {
      state.generatedOfferImage = (action.payload);
    }
  },
});

export const offerGenerator = (state: RootState) => state.offerGenerator;
export const { setSelectedOffer, addGeneratedOffer, setSelectedPrompt, setSelectedResponse, setIsFromOfferDetails, updateExistingOffer, setGeneratedOfferImage} = offerGeneratorSlice.actions;

export default offerGeneratorSlice.reducer;
