import { setSelectedOffer, updateExistingOffer } from "app/slices/offerGenerator";
import { store } from "app/store";
import { Icon } from "components/shared/icon";
import { Loader } from "components/shared/loader";
import { LoaderColor, LoaderSize } from "components/shared/loader/Loader.consts";
import { makeGenAICall, promptItem } from "pages/genAI/genAI/genAI";
import { useState } from "react";
import OfferGeneratorItemButton from "../offerGeneratorItemButton/OfferGeneratorItemButton";
import { EditPromptInput, EditWrapper, PromptWrapper, RatingIconsWrapper, ResponseTitleWrapper, ResponseWrapper, TextWrapper } from "./OfferGeneratorResponse.style";

interface offerGeneratorResponseProps {
    promptItem: promptItem,
    onResponseItemClick: (item: promptItem) => void; 
    selectedOffer: promptItem; 
}


const OfferGeneratorResponse = ({promptItem, onResponseItemClick, selectedOffer} : offerGeneratorResponseProps) => {
    const [isInEdit, setIsInEdit] = useState<boolean>(null); 
    const [loading, setIsLoading] = useState<boolean>(false); 
    const handleEditClick = () => {
        setIsInEdit(true); 
        setTempPrompt(promptItem.prompt); 
    }
    const [tempPrompt, setTempPrompt] = useState<string>(null); 
   

    const handleEditSubmit = async () => {
        const inputBody = {
            prompt: tempPrompt,
        }
        store.dispatch(setSelectedOffer(null)); 

        if (tempPrompt !== promptItem.prompt) {
            setIsLoading(true); 
            setIsInEdit(false); 
            const { response, error } = await makeGenAICall(inputBody); 
            if (response) {
              
                store.dispatch(updateExistingOffer({id: promptItem.id, prompt: tempPrompt, response: response.data })); 
            }
            setIsLoading(false); 
        }
    }

    const handlePromptChange = (e : any) => {
        setTempPrompt(e.target.value);    
    }

    const handleCancelEdit = () => {
        setIsInEdit(false); 
        
    }

    return <>
    <PromptWrapper>
        <TextWrapper>
            <Icon name='yellowProfile' width={15} height={15}/>
            {isInEdit ? <EditPromptInput onChange={handlePromptChange} type="text" id="inputValue" name="inputValue" value={tempPrompt}/> : promptItem.prompt }
        </TextWrapper>
        <EditWrapper>
            {isInEdit ? (
                <>
                 <div onClick={handleCancelEdit}>
                    <Icon name='close' />
                </div>
                <div onClick={handleEditSubmit}>
                    <Icon name='submitPrompt' width={20} height={20} />
                </div>
                </>
                )
                :  loading ? 
                    <Loader size={LoaderSize.Small} color={LoaderColor.Secondary}/>  : 
                    <div onClick={handleEditClick}>
                        <Icon name='promptEdit' width={24} height={24}/>         
                    </div>
            }
        </EditWrapper>    
    </PromptWrapper>
    <ResponseWrapper>
        <ResponseTitleWrapper>
        <TextWrapper>
            <Icon name='mcdSmile' width={30} height={30}/>
            Sure, here is an offer with the title {promptItem.response.versions[0].translationsMap['en-US'].title}
        </TextWrapper>
        <RatingIconsWrapper> 
            <Icon name='thumbsUp' width={21} height={21} />
            <Icon name='thumbsDown' width={21} height={21} />
        </RatingIconsWrapper>
        </ResponseTitleWrapper>
        <OfferGeneratorItemButton selectedOffer={selectedOffer} onResponseItemClick={onResponseItemClick} promptItem={promptItem}/>
    </ResponseWrapper>
    </>

     

}

export default OfferGeneratorResponse; 