import styled from "@emotion/styled";
import typography from 'styles/typography';
import { StyledWithThemeProps } from "utils/types";

export const StyledLargePageTitle = styled.h1<StyledWithThemeProps>`

    color: ${({ theme }) => theme.colors.text.primary};
    font-family: ${typography.primaryFont};
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

`