


import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { GenAILink, PageTitle, SubTitle, TitleWrapper, Wrapper } from './AIOfferGeneratorHome.style';
import { defaultTheme } from 'styles/themes/defaultTheme';
import { Icon } from 'components/shared/icon';


const AIOfferGeneratorHome = () => {
    return (
        <PageContainer>
            <Wrapper theme={defaultTheme}>
                <TitleWrapper>
                    <Icon name='mcdSmile' width={57} height={57}/>
                    <PageTitle theme={defaultTheme}>Create offers, faster & smarter</PageTitle>
                    <SubTitle theme={defaultTheme}>Welcome to the AI Offer Engine! Speed up your offer creation workflow using the power of machine learning.</SubTitle>
                    <GenAILink theme={defaultTheme} to={'/offer-details'} >Get Started</GenAILink>
                </TitleWrapper>
            </Wrapper>
        </PageContainer>
    )
}

export default AIOfferGeneratorHome;