import { defaultTheme } from "styles/themes/defaultTheme";
import { StyledLargePageTitle } from "./LargePageTitle.style";


interface LargePageTitleProps {
    text: string; 
}

const LargePageTitle = ({ text } : LargePageTitleProps  ) => {

    return <StyledLargePageTitle theme={defaultTheme}>
        {text}
    </StyledLargePageTitle>




}

export default LargePageTitle; 