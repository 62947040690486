import styled from "@emotion/styled";
import typography from "styles/typography";
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { NewSelectbox } from "components/shared/selectbox/NewSelectbox";

export const PageSubtitle = styled.h3`
    color: #292929;
    font-family: ${typography.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
export const ModelSelectBox = styled(NewSelectbox)`
    width: 50%;
`
export const ModelSelection = styled.div`
    display: flex; 
    flex-direction: row; 
    gap: 18px; 
    align-items: center; 
`

export const OfferSpecifics = styled.div`
    border-radius: 5px;
    background: #F5F5F5;
    width: 100%; 
    padding: 20px; 
    display: flex;
    flex-direction: column; 
    gap: 18px; 
    font-family: ${typography.primaryFont};
    margin-bottom: -20px; 
    


`

export const GridColumn = styled.div`
    display: flex;
    flex-direction: column; 
    gap: 18px; 
    width: 100%; 

`

export const GridWrapper = styled.div`
    display: flex;
    flex-direction: row; 
    gap: 18px; 

`

export const OfferSpecificsTitle = styled.div`
color: #292929;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;

`