import React, { useEffect, useState } from 'react';
import { closeModal } from 'app/slices/modals';
import { store } from 'app/store';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import TextField from 'components/shared/textField/TextField';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Checkbox from 'components/shared/checkbox/Checkbox';
import ReactTooltip from 'react-tooltip';
import { capitalize } from 'utils/text';
import { FormMode } from 'utils/types';
import { useMutation } from '@apollo/client';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { AuthType, UserNotificationRecord, UserNotificationType, UserRole, UserRoleRecord } from 'utils/types/users';
import { hideTooltip } from 'utils/tooltip';
import ToggleGroup from 'components/shared/toggleGroup/ToggleGroup';
import { StyledLabel } from 'components/shared/textField/TextField.style';
import { isInArray } from 'utils/array';
import {
  CheckboxWrapper,
  NotificationRegisteration,
  RoleSelectbox,
  StyledInfoTooltip,
  StyledModal,
  StyledTooltip,
  UserManagementModalColumn,
  UserManagementModalCompany,
  UserManagementModalContainer,
  UserManagementModalFooter,
  UserManagementModalHorizontalColumn,
  UserManagementModalNames,
} from './UserManagementModal.style';
import { AuthTypes, Companies, UserManagementModalProps } from './UserManagementModal.consts';
import usersGqls from '../../UserManagement.gqls';
import { NotificationActionType } from 'utils/types/notification';
import { ValidationMessages } from 'utils/types/common';

const UserManagementModal = ({ user, mode }: UserManagementModalProps) => {
  const {
    control,
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: cloneDeep(omitBy(user, isNil)) as any,
  });

  const [modalMode, setModalMode] = useState(mode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createUser] = useMutation(usersGqls.mutations.add);
  const [updateUser] = useMutation(usersGqls.mutations.update);
  const [deleteUser] = useMutation(usersGqls.mutations.delete);
  const [unlockUser] = useMutation(usersGqls.mutations.unlock);
  const [firstName, lastName, authType, role] = useWatch({
    control,
    name: ['firstName', 'lastName', 'authType', 'role'],
  });
  const [notifications, setNotifications] = useState(user?.notifications ?? []);

  const onSave = async (formData: any) => {
    setIsSubmitting(true);
    if (modalMode === FormMode.Edit) {
      try {
        await updateUser({
          variables: {
            data: {
              id: formData.id,
              role: formData.role === UserRole.SysAdmin ? 'SysAdmin' : capitalize(formData.role),
              company: formData.company,
              notifications: [UserRole.Trainee, UserRole.Viewer].includes(formData.role)
                ? formData.notifications
                  ? formData.notifications.filter(
                      (ele: NotificationActionType) => ele !== NotificationActionType.CampaignApprovalRequired,
                    )
                  : []
                : formData.notifications ?? [],
              firstName: formData.authType === AuthType.VCE ? firstName : undefined,
              lastName: formData.authType === AuthType.VCE ? lastName : undefined,
            },
          },
          refetchQueries: ['Users'],
        });
        showToast(MessageType.Success, `User updated successfully`);
        store.dispatch(closeModal());
      } catch (e) {
        showToast(MessageType.Error, `Failed to update user`);
      }
    } else if (modalMode === FormMode.New) {
      try {
        await createUser({
          variables: {
            data: {
              email: formData.email,
              role: capitalize(formData.role),
              company: formData.company,
              notifications: [UserRole.Trainee, UserRole.Viewer].includes(formData.role)
                ? formData.notifications
                  ? formData.notifications.filter(
                      (ele: NotificationActionType) => ele !== NotificationActionType.CampaignApprovalRequired,
                    )
                  : []
                : formData.notifications ?? [],
              authType: formData.authType.toUpperCase(),
              firstName: formData.authType === AuthType.VCE ? firstName : undefined,
              lastName: formData.authType === AuthType.VCE ? lastName : undefined,
            },
          },
          refetchQueries: ['Users'],
        });
        showToast(MessageType.Success, `User added successfully`);
        store.dispatch(closeModal());
      } catch (e) {
        if (e.message.includes('Access Denied')) {
          showToast(MessageType.Error, `Failed to add user - Access Denied`);
        } else if (e.message.includes('already exists')) {
          showToast(MessageType.Error, `Failed to add user - ${formData.email} already exists`);
        } else {
          showToast(MessageType.Error, `Failed to add user`);
        }
      }
    }
    setIsSubmitting(false);
  };

  const getModalTitle = () => {
    switch (modalMode) {
      case FormMode.New:
        return 'Add User';
      case FormMode.Edit:
        return `Edit User ${user.email}`;
      case FormMode.View:
        return `View User ${user.email}`;
      default:
        return '';
    }
  };

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);

  const handleDeleteClick = async () => {
    if (!isDeleteButtonClicked) {
      setIsDeleteButtonClicked(true);
      await deleteUser({
        variables: {
          id: Number(user.id),
        },
        refetchQueries: ['Users'],
      });
      hideTooltip('#delete-tooltip');
      ReactTooltip.hide();
    }
  };

  const getIsNotificationDisabled = (key: UserNotificationType) =>
    key === UserNotificationType.CampaignRequireApproval && isInArray([UserRole.Trainee, UserRole.Viewer], role);

  const handleProductOrLocationUpdate = (userNotifications: string[]) => {
    const keysToDelete = [];
    if (!userNotifications.includes(UserNotificationType.ProductUpdate) && userNotifications.includes('noProducts')) {
      keysToDelete.push('noProducts');
    }
    if (!userNotifications.includes(UserNotificationType.LocationUpdate) && userNotifications.includes('noLocations')) {
      keysToDelete.push('noLocations');
    }
    return keysToDelete;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty]);

  useEffect(() => {
    if (modalMode !== FormMode.View && modalMode !== FormMode.Edit && authType === AuthType.VCE) {
      setValue('company', 'Other');
    }
  }, [authType]);

  return (
    <StyledModal title={getModalTitle()}>
      <UserManagementModalContainer>
        <UserManagementModalColumn>
          <header>
            <StyledLabel disabled={modalMode !== FormMode.New}>Authentication*</StyledLabel>
            <ToggleGroup
              itemList={AuthTypes}
              control={control}
              name="authType"
              defaultValue="gas"
              disabled={modalMode !== FormMode.New}
            />
          </header>
          <TextField
            register={register}
            errors={errors}
            name="email"
            label="Email"
            placeholder="Enter"
            labelIsHorizontal
            type="email"
            value={user?.email}
            validation={{
              required: true,
              pattern: {
                value:
                  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid Email',
              },
            }}
            disabled={modalMode !== FormMode.New}
          />
          {authType === AuthType.VCE ? (
            <UserManagementModalNames>
              <TextField
                register={register}
                errors={errors}
                name="firstName"
                label="First Name"
                labelIsHorizontal
                disabled={!isInArray([FormMode.New, FormMode.Edit], modalMode)}
                validation={{
                  required: ValidationMessages.RequiredField,
                }}
              />
              <TextField
                register={register}
                errors={errors}
                name="lastName"
                label="Last Name"
                labelIsHorizontal
                disabled={!isInArray([FormMode.New, FormMode.Edit], modalMode)}
                validation={{
                  required: ValidationMessages.RequiredField,
                }}
              />
            </UserManagementModalNames>
          ) : null}
          <NotificationRegisteration>
            <span>Email Notifications</span>
            {Object.entries(UserNotificationRecord).map(([key, val]: [string, string]) => {
              return (
                <Controller
                  control={control}
                  name="notifications"
                  render={({ field: { onChange } }) => (
                    <CheckboxWrapper>
                      <Checkbox
                        disabled={modalMode === FormMode.View || getIsNotificationDisabled(key as UserNotificationType)}
                        label={val === 'Campaign Error Notification' ? 'Campaign Notification' : val}
                        onClick={() => {
                          const newNotifications = [...notifications];
                          if (notifications.includes(key)) {
                            newNotifications.splice(notifications.indexOf(key), 1);
                            const keysToRemove = handleProductOrLocationUpdate(newNotifications);
                            keysToRemove.forEach((k) => newNotifications.splice(newNotifications.indexOf(k), 1));
                          } else {
                            newNotifications.push(key);
                          }
                          setNotifications(newNotifications);
                          onChange(newNotifications);
                        }}
                        checked={notifications.includes(key) && !getIsNotificationDisabled(key as UserNotificationType)}
                      />
                      {val === 'Campaign Error Notification' && (
                        <StyledInfoTooltip
                          id="uptoTooltip"
                          className="userManagementCampaignNotification"
                          content="User will receive bulk campaign notification and campaign error notification if enabled."
                        />
                      )}
                    </CheckboxWrapper>
                  )}
                />
              );
            })}
          </NotificationRegisteration>
        </UserManagementModalColumn>
        <UserManagementModalHorizontalColumn>
          {user?.role !== UserRole.SysAdmin && (
            <RoleSelectbox
              items={
                Object.entries(UserRoleRecord)
                  .filter(([key]) => key !== UserRole.SysAdmin)
                  .map(([key, value]) => {
                    return {
                      id: key,
                      name: value,
                    };
                  }) as any[]
              }
              control={control}
              name="role"
              errors={errors}
              label="Role"
              labelIsHorizontal
              validation={{ required: true }}
              onChange={() => {
                trigger('role');
              }}
              disabled={modalMode === FormMode.View}
              selectWidth={100}
            />
          )}
          <UserManagementModalCompany>
            <span>Company*</span>
            <RadioGroup
              control={control}
              name="company"
              radioList={Companies}
              defaultValue={Companies[0].value}
              disabled={modalMode === FormMode.View}
            />
          </UserManagementModalCompany>
        </UserManagementModalHorizontalColumn>
        <UserManagementModalFooter>
          <ButtonText
            data-for="cancel-tooltip"
            {...(isDirty ? { 'data-tip': true, onClick: () => null } : { onClick: () => store.dispatch(closeModal()) })}
          >
            Cancel
          </ButtonText>
          {modalMode === FormMode.View ? (
            <>
              <StyledTooltip
                id="delete-tooltip"
                content={`Are you sure you want to delete ${user.email}?`}
                approveMsg="Yes, Delete"
                onDisapproveClick={() => {
                  hideTooltip('#delete-tooltip');
                }}
                onApproveClick={() => {
                  handleDeleteClick();
                  showToast(MessageType.Success, `User deleted successfully`);
                  store.dispatch(closeModal());
                }}
              />
              <ButtonOutlined data-tip data-for="delete-tooltip" onClick={() => null}>
                Delete
              </ButtonOutlined>
              {user?.isLocked === true ? (
                <ButtonOutlined
                  onClick={async () => {
                    try {
                      await unlockUser({
                        variables: {
                          id: user.id,
                        },
                        refetchQueries: ['Users'],
                      });
                      showToast(MessageType.Success, `User unlocked successfully`);
                      store.dispatch(closeModal());
                    } catch (error) {
                      showToast(MessageType.Error, `User unlock failed, Please try again`);
                      // Handle error
                    }
                  }}
                >
                  Unlock
                </ButtonOutlined>
              ) : (
                <ButtonContained onClick={() => setModalMode(FormMode.Edit)}>Edit</ButtonContained>
              )}
            </>
          ) : (
            <>
              <ButtonContained onClick={handleSubmit(onSave)} disabled={!isValid || !isDirty || isSubmitting}>
                Save
              </ButtonContained>
              <StyledTooltip
                id="cancel-tooltip"
                content="Are you sure you want to cancel?"
                onDisapproveClick={() => {
                  hideTooltip('#cancel-tooltip');
                }}
                onApproveClick={() => store.dispatch(closeModal())}
              />
            </>
          )}
        </UserManagementModalFooter>
      </UserManagementModalContainer>
    </StyledModal>
  );
};

export default UserManagementModal;
