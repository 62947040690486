import { User, UserRole } from 'utils/types/users';
import { Tab } from 'components/shared/tabStrip/TabStrip.consts';
import { appTheme } from 'styles/themes/defaultTheme';
import { DOEMarketConfigurationKey, MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import { Feature } from 'utils/types/features';

export type Environments = keyof Record<EnvironmentType, string>;

export enum EnvironmentType {
  Local = 'local',
  Dev = 'dev',
  Test = 'test',
  Int = 'int',
  Mte = 'mte',
  Stage = 'stage',
  Prod = 'prod',
}

export interface styledHeaderContainer {
  environment: Environments;
  className?: string;
  theme?: appTheme;
}

export interface HeaderProps {
  user?: User;
}

export enum tabName {
  Dashboard = 'dashboard',
  Campaigns = 'campaigns',
  CampaignManagement = 'campaign management',
  ScheduleManagement = 'schedule management',
  Calendar = 'calendar',
  Offers = 'offers',
  OfferManagement = 'offer management',
  Reports = 'reports',
  Audit = 'audit',
  ProductChanges = 'product changes',
  LocationChanges = 'location changes',
  Redemptions = 'redemptions',
  Settings = 'settings',
  Images = 'images',
  TermsConditions = 'terms & conditions',
  Tags = 'tags',
  ProductSets = 'custom product sets',
  LocationSets = 'custom location sets',
  UserManagement = 'user management',
  Configurations = 'configurations',
  Features = 'features',
  SchedulePeriods = 'schedule periods',
  DealsPriorityConfiguration = 'campaign priority',
  DoeCampaigns = 'DOE campaigns',
  DoeOffers = 'DOE offers',
  DoeImages = 'DOE images',
  AIOfferGeneratorHome = 'AI offer generator',
  OfferGenerator = 'offer generator',
  OfferDetails = 'offer details',
  MeasurementDashboard = 'measurement dashboard'
}

export const tabToPathMap: Partial<Record<tabName, string>> = {
  [tabName.Dashboard]: '/',
  [tabName.Campaigns]: '/campaigns',
  [tabName.CampaignManagement]: '/campaign-management',
  [tabName.ScheduleManagement]: '/schedule-management',
  [tabName.Calendar]: '/calendar',
  [tabName.Offers]: '/offers',
  [tabName.OfferManagement]: '/offer-management',
  [tabName.Images]: '/images',
  [tabName.TermsConditions]: '/terms-conditions',
  [tabName.Tags]: '/tags',
  [tabName.ProductSets]: '/product-sets',
  [tabName.LocationSets]: '/location-sets',
  [tabName.UserManagement]: '/user-management',
  [tabName.Audit]: '/audit',
  [tabName.Redemptions]: '/redemptions',
  [tabName.ProductChanges]: '/product-changes',
  [tabName.LocationChanges]: '/location-changes',
  [tabName.Configurations]: '/configurations',
  [tabName.Features]: '/features',
  [tabName.SchedulePeriods]: '/schedule-periods',
  [tabName.DoeCampaigns]: '/doe-campaigns',
  [tabName.DoeOffers]: '/doe-offers',
  [tabName.DoeImages]: '/doe-images',
  [tabName.DealsPriorityConfiguration]: '/campaign-priority',
  [tabName.AIOfferGeneratorHome]: '/gen-ai',
  [tabName.OfferGenerator]: '/offer-generator',
  [tabName.OfferDetails]: '/offer-details', 
  [tabName.MeasurementDashboard]: '/measurement-dashboard', 
};

export const tabToHelpPathMap: Partial<Record<tabName, string>> = {
  [tabName.Dashboard]: 'dashboard',
  [tabName.Campaigns]: 'campaigns',
  [tabName.CampaignManagement]: 'campaigns',
  [tabName.ScheduleManagement]: 'campaigns/a/SM',
  [tabName.Calendar]: 'campaigns/a/h2_1292798904',
  [tabName.Offers]: 'offer-user-guide',
  [tabName.OfferManagement]: 'offer-user-guide',
  [tabName.Images]: 'images-user-guide-includes-admin',
  [tabName.TermsConditions]: 't-c',
  [tabName.Tags]: 'tags',
  [tabName.ProductSets]: 'custom-product-sets',
  [tabName.LocationSets]: 'custom-location-sets',
  [tabName.UserManagement]: 'user-management',
  [tabName.Audit]: 'audit-log',
  [tabName.Redemptions]: 'redemptions-over-time-report',
  [tabName.ProductChanges]: 'product-change-log',
  [tabName.LocationChanges]: 'location-change-log',
  [tabName.SchedulePeriods]: 'schedule-periods',
};

export const getTabByPath = (path: string) =>
  (Object.keys(tabToPathMap) as tabName[]).find((key) => tabToPathMap[key] === path);

export const mainTabValues = Object.values(tabName);

export const mainTabs: Tab[] = [
  // Dashboard tab
  { id: `tab-${tabName.Dashboard}`, title: tabName.Dashboard, link: tabToPathMap[tabName.Dashboard] },
  // Campaigns tab
  {
    id: `tab-${tabName.Campaigns}`,
    title: tabName.Campaigns,
    link: tabToPathMap[tabName.Campaigns],
    marketConfigurations: [
      { configKey: MarketConfigurationKey.EnableManagementByZone, value: false },
      { configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: false },
    ],
    features: [{ feature: Feature.Calendar, value: false }],
  },
  {
    id: `tab-${tabName.Campaigns}`,
    title: tabName.Campaigns,
    marketConfigurations: [
      { configKey: MarketConfigurationKey.EnableManagementByZone, value: false },
      { configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: true },
    ],
    features: [{ feature: Feature.Calendar, value: false }],
    children: [
      {
        id: `tab-${tabName.CampaignManagement}`,
        title: tabName.CampaignManagement,
        link: tabToPathMap[tabName.CampaignManagement],
      },
      {
        id: `tab-${tabName.DoeCampaigns}`,
        title: tabName.DoeCampaigns,
        link: tabToPathMap[tabName.DoeCampaigns],
      },
    ],
  },
  {
    id: `tab-${tabName.Campaigns}`,
    title: tabName.Campaigns,
    marketConfigurations: [{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }],
    children: [
      {
        id: `tab-${tabName.CampaignManagement}`,
        title: tabName.CampaignManagement,
        link: tabToPathMap[tabName.CampaignManagement],
      },
      {
        id: `tab-${tabName.ScheduleManagement}`,
        title: tabName.ScheduleManagement,
        link: tabToPathMap[tabName.ScheduleManagement],
      },
      {
        id: `tab-${tabName.Calendar}`,
        title: tabName.Calendar,
        link: tabToPathMap[tabName.Calendar],
        features: [{ feature: Feature.Calendar }],
      },
      {
        id: `tab-${tabName.DoeCampaigns}`,
        title: tabName.DoeCampaigns,
        marketConfigurations: [{ configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: true }],
        link: tabToPathMap[tabName.DoeCampaigns],
      },
    ],
  },
  {
    id: `tab-${tabName.Campaigns}`,
    title: tabName.Campaigns,
    marketConfigurations: [{ configKey: MarketConfigurationKey.EnableManagementByZone, value: false }],
    features: [{ feature: Feature.Calendar, value: true }],
    children: [
      {
        id: `tab-${tabName.CampaignManagement}`,
        title: tabName.CampaignManagement,
        link: tabToPathMap[tabName.CampaignManagement],
      },
      {
        id: `tab-${tabName.Calendar}`,
        title: tabName.Calendar,
        link: tabToPathMap[tabName.Calendar],
        features: [{ feature: Feature.Calendar }],
      },
      {
        id: `tab-${tabName.DoeCampaigns}`,
        title: tabName.DoeCampaigns,
        marketConfigurations: [{ configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: true }],
        link: tabToPathMap[tabName.DoeCampaigns],
      },
    ],
  },
  // Offers tab
  {
    id: `tab-${tabName.Offers}`,
    title: tabName.Offers,
    link: tabToPathMap[tabName.Offers],
    marketConfigurations: [{ configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: false }],
  },
  {
    id: `tab-${tabName.Offers}`,
    title: tabName.Offers,
    marketConfigurations: [{ configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: true }],
    children: [
      {
        id: `tab-${tabName.OfferManagement}`,
        title: tabName.OfferManagement,
        link: tabToPathMap[tabName.OfferManagement],
      },
      {
        id: `tab-${tabName.DoeOffers}`,
        title: tabName.DoeOffers,
        link: tabToPathMap[tabName.DoeOffers],
      },
    ],
  },
  // Reports tab
  {
    id: `tab-${tabName.Reports}`,
    title: tabName.Reports,
    children: [
      { id: `tab-${tabName.Audit}`, title: tabName.Audit, link: tabToPathMap[tabName.Audit] },
      {
        id: `tab-${tabName.ProductChanges}`,
        title: tabName.ProductChanges,
        link: tabToPathMap[tabName.ProductChanges],
      },
      {
        id: `tab-${tabName.LocationChanges}`,
        title: tabName.LocationChanges,
        link: tabToPathMap[tabName.LocationChanges],
      },
      {
        id: `tab-${tabName.Redemptions}`,
        title: tabName.Redemptions,
        link: tabToPathMap[tabName.Redemptions],
      },
    ],
  },
  // Settings tab
  {
    id: `tab-${tabName.Settings}`,
    title: tabName.Settings,
    children: [
      {
        id: `tab-${tabName.Images}`,
        title: tabName.Images,
        link: `${tabToPathMap[tabName.Images]}`,
      },
      {
        id: `tab-${tabName.TermsConditions}`,
        title: tabName.TermsConditions,
        link: `${tabToPathMap[tabName.TermsConditions]}`,
        roles: [UserRole.SysAdmin, UserRole.Admin],
      },
      {
        id: `tab-${tabName.Tags}`,
        title: tabName.Tags,
        link: tabToPathMap[tabName.Tags],
        roles: [UserRole.SysAdmin, UserRole.Admin],
      },
      {
        id: `tab-${tabName.ProductSets}`,
        title: tabName.ProductSets,
        link: tabToPathMap[tabName.ProductSets],
        roles: [UserRole.SysAdmin, UserRole.Admin],
      },
      {
        id: `tab-${tabName.LocationSets}`,
        title: tabName.LocationSets,
        link: tabToPathMap[tabName.LocationSets],
        roles: [UserRole.SysAdmin, UserRole.Admin],
      },
      {
        id: `tab-${tabName.UserManagement}`,
        title: tabName.UserManagement,
        link: tabToPathMap[tabName.UserManagement],
        roles: [UserRole.SysAdmin, UserRole.Admin],
      },
      {
        id: `tab-${tabName.SchedulePeriods}`,
        title: tabName.SchedulePeriods,
        link: tabToPathMap[tabName.SchedulePeriods],
        roles: [UserRole.SysAdmin, UserRole.Admin],
        marketConfigurations: [{ configKey: MarketConfigurationKey.EnableSchedulePeriods, value: true }],
      },
      {
        id: `tab-${tabName.DealsPriorityConfiguration}`,
        title: tabName.DealsPriorityConfiguration,
        link: tabToPathMap[tabName.DealsPriorityConfiguration],
      },
      {
        id: `tab-${tabName.DoeImages}`,
        title: tabName.DoeImages,
        marketConfigurations: [{ configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: true }],
        link: tabToPathMap[tabName.DoeImages],
      },
    ],
  },
  {
    id: `tab-${tabName.AIOfferGeneratorHome}`,
    title: tabName.AIOfferGeneratorHome,
    link: tabToPathMap[tabName.AIOfferGeneratorHome]
  },
];
