import { Loader } from "components/shared/loader"
import { getRandomInt, makeGenAICall, mockBOGO, promptItem } from "pages/genAI/genAI/genAI"
import { useSelector } from "react-redux"
import { defaultTheme } from "styles/themes/defaultTheme"
import { createNewOffer } from "utils/api/offers"
import { BaseOffer } from "utils/types/offers"
import { OfferMobileView } from "./components/offerMobileView/OfferMobileView"
import { ButtonWrapper, OfferPreviewTitle, OfferPreviewWrapper, PredictedMargin, PredictedMarginBox, PredictedMarginTitle, PublishBtn, RegenerateBtn, RegenerateLink } from "./OfferPreview.style"
import { offerDetails as offerDetailsSlice, setFormValue, resetFormValues } from 'app/slices/offerDetails';
import { addGeneratedOffer, offerGenerator as offerGeneratorSlice, setGeneratedOfferImage, setIsFromOfferDetails, setSelectedOffer, updateExistingOffer } from 'app/slices/offerGenerator';
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { store } from "app/store"
import { LoaderColor, LoaderSize } from "components/shared/loader/Loader.consts"

export interface OfferPreviewProps  {
    fromPage: 'details' | 'generator'
    predictedMargin?: number,
    isLoading?: boolean,
    addPrompt?: ({ prompt, response, id } : promptItem) => void; 
}

const OfferPreview = ({ fromPage, predictedMargin, isLoading }  : OfferPreviewProps) => {

    const { selectedOffer  } = useSelector(offerGeneratorSlice);
    const { formValues } = useSelector(offerDetailsSlice);
    const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false); 

    const handlePublish = async () => {
            setIsPublishLoading(true)
            await createNewOffer(selectedOffer.response as unknown as BaseOffer, false); 
            store.dispatch(setSelectedOffer(null)); 
            setIsPublishLoading(false)
            history.push('/offers')
    }

    useEffect(() => {
        setIsPublishLoading(false)
        store.dispatch(setIsFromOfferDetails(false)); 
    }, [])

    const addOffer = ({ prompt ,response, id } : promptItem ) => {
        const newPrompt = { prompt, response, id};
        store.dispatch(addGeneratedOffer(newPrompt));
    }

    const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false); 

    const history = useHistory();

    const [error, setError] = useState(null); 

    // Generate from offer details form. 
    const handleGenerate = async (isRegenerate?: boolean) => {
        setError(null)
        setIsGenerateLoading(true); 
       let inputBody; 
       let generatePromptFromForm; 
       if (!isRegenerate) {
        generatePromptFromForm = `I want a ${formValues.templateType.name} offer, with a discount amount of ${formValues.discountAmount} and a title of ${formValues.offerTitle}`
       }
       if (formValues.offerTitle) {
        inputBody = {
            prompt: generatePromptFromForm,
            offerTitle: formValues.offerTitle,
            templateType: formValues.templateType.name,
            discountAmount: formValues.discountAmount,
        }
        }
        if (isRegenerate) {
            inputBody = {
                prompt: selectedOffer.prompt,
            }
        }
     
        const { response, error } = await makeGenAICall(inputBody); 

        if (response) {
            if (isRegenerate) {
                store.dispatch(updateExistingOffer({id: selectedOffer.id, prompt: selectedOffer.prompt, response: response.data })); 
                store.dispatch(setSelectedOffer({id: selectedOffer.id, prompt: selectedOffer.prompt, response: response.data })); 
            }
            else {
                let promptItem = {prompt: generatePromptFromForm, response: response.data, id: `${formValues.offerTitle}${getRandomInt(1,100000)}`}
                addOffer(promptItem); 
                store.dispatch(setIsFromOfferDetails(true)); 
                history.push('/offer-generator');
            }
            setIsGenerateLoading(false)
        }
        if (error) {
            setError(error)
            setIsGenerateLoading(false)
        }
     
    }

    return <OfferPreviewWrapper> 
            {fromPage === 'generator' && <OfferPreviewTitle theme={defaultTheme}>
                Offer Details
            </OfferPreviewTitle>} 
            {fromPage === 'generator' ? 
            <OfferMobileView selectedOffer={selectedOffer} /> : 
            <PredictedMarginBox>
                <PredictedMarginTitle>
                    Predicted Incremental Margin
                </PredictedMarginTitle>
                <PredictedMargin>
                    {isLoading ? <Loader size={LoaderSize.Small} /> : predictedMargin ? `$${predictedMargin}` : ''}
                </PredictedMargin>
            </PredictedMarginBox>
        }  
        <ButtonWrapper>
            {fromPage === 'generator' ? 
                <>
                <RegenerateBtn 
                    selectedOffer={selectedOffer}
                    fromPage={fromPage}
                    onClick={() => handleGenerate(true)}
                >
                     {isGenerateLoading ? <Loader size={LoaderSize.ExtraSmall} /> : 'Regenerate Image & Text'}
                </RegenerateBtn>
                {error && <div>{error}</div>}
                <PublishBtn
                     selectedOffer={selectedOffer}
                     fromPage={fromPage}
                     onClick={handlePublish}
                >
                     {isPublishLoading ? <Loader size={LoaderSize.ExtraSmall}  color={LoaderColor.Secondary}/> : 'Publish'}
                </PublishBtn>
                </>
        : 
        <>
            <RegenerateBtn
                predictedMargin={predictedMargin}
                fromPage={fromPage}
                onClick={() => handleGenerate(false)}
            >
                {isGenerateLoading ? <Loader size={LoaderSize.ExtraSmall}/> : 'Generate Offers'}
                {error && <div>{error}</div>}
            </RegenerateBtn>
            <RegenerateLink
                predictedMargin={predictedMargin}
                fromPage={fromPage}
                to='/offers'
            >
                Explore Similar Offers
            </RegenerateLink></>
        }
         </ButtonWrapper>
    </OfferPreviewWrapper>
}

export default OfferPreview