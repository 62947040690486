import { Icon } from "components/shared/icon/Icon";
import { promptItem } from "pages/genAI/genAI/genAI";
import { Interface } from "readline";
import { defaultTheme } from "styles/themes/defaultTheme";
import { OfferPreviewProps } from "../../OfferPreview";

import { MobileViewWrapper, MobileViewTitle, MobileViewDescription, MobileViewMenu, MenuOption, MenuTitle, MenuOptionFlex, TitleWrapper } from "./OfferMobileView.style";
import { useEffect, useState } from "react";

interface offerMobileViewProps {
    selectedOffer: promptItem
}
export const OfferMobileView = ({ selectedOffer } : offerMobileViewProps ) => {

        const [trimmedDescription, setTrimmedDescription] = useState<string>(); 

        useEffect(() => {
            if (selectedOffer) {
                const match = selectedOffer.response.versions[0].translationsMap['en-US'].description.match(/.*?[.!?](\s|$)/);
                setTrimmedDescription(match ? match[0] : ''); 
            }
          
        }, [selectedOffer])

        return (
            <MobileViewWrapper>
                {selectedOffer && 
                <><div style={{paddingBottom: '10px'}}>
                     <Icon  name='close' width={16} height={16} /> </div>
                 </>}
                 <TitleWrapper>
                 {selectedOffer && 
                 <MenuOptionFlex>
                        <Icon name='Deals' width={16} height={16} />
                        Deal
                    </MenuOptionFlex>}
                    <MobileViewTitle
                    selectedOffer={selectedOffer ? true : false}
                    theme={defaultTheme}>
                        {selectedOffer ? selectedOffer.response.versions[0].translationsMap['en-US'].title : 'Select a generated offer to see an offer preview'}
                    </MobileViewTitle>
                 </TitleWrapper>
                    <MobileViewDescription  theme={defaultTheme}>
                        {selectedOffer && trimmedDescription}
                        </MobileViewDescription>
                    {selectedOffer &&  selectedOffer.response.versions[0].translationsMap['en-US'].image && <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <img width='125px' height='125px' src={selectedOffer.response.versions[0].translationsMap['en-US'].image.fileName}></img></div>}
            
            {selectedOffer && 
                <MobileViewMenu>
                <MenuTitle>
                    Choose how to use your deal
                </MenuTitle>
                <MenuOption>
                    <MenuOptionFlex>
                        Add To Mobile Order 
                    </MenuOptionFlex>
                    <Icon name='arrowRight' width={9} height={9}/>
                </MenuOption>
                <MenuOption>
                <MenuOptionFlex>
                        Use at Restaurant
                        
                    </MenuOptionFlex>
                    <Icon name='arrowRight' width={9} height={9}/>
                </MenuOption>
            </MobileViewMenu>
            }
            </MobileViewWrapper>
        )
}