import { tabName } from "components/header/Header.consts";
import axios from 'axios'; 
import { createNewImages } from "utils/api/images";
import { generateOffer } from "utils/api/offers";

interface NavItemType {
    title: string,
    url: string,
    id: string, 
    subTab?: boolean,
}

export interface promptItem {
    prompt: string, 
    response: {[key: string]: any }
    key?: number,
    id: string,
}

export const navItems = [
    {
        title: 'Offer Details', 
        url: '/offer-details', 
        id: `tab-${tabName.OfferDetails}`,
    },
    {
        title: 'Offer Generator', 
        url: '/offer-generator', 
        id: `tab-${tabName.OfferGenerator}`,
    }, 
    {
        title: 'Available Offers', 
        url: '/offers', 
        id: `tab-${tabName.Offers}`,
    }
] as NavItemType[]


export const subNavItems = [

  {
    title: 'Measurement Dashboard', 
    url: '/measurement-dashboard', 
    id: `tab-${tabName.MeasurementDashboard}`,
    subTab: true,
}
  
] as NavItemType[]


export const mockBOGO =  {
    "versions": [
        {
            "translationsMap": {
                "en-US": {
                    "language": "en-US",
                    "image": {
                        "id": 100035,
                        "name": "fries",
                        "fileName": "generated_image.jpg",
                        "isArchive": false,
                        "description": "des",
                        },
                    "title": "Gen AI BOGO Test with generated image",
                    "posTitle": "",
                    "subtitle": "",
                    "description": ""
                },
                "es-ES": {
                    "language": "es-ES",
                    "image": "",
                    "title": "",
                    "posTitle": "",
                    "subtitle": "",
                    "description": ""
                }
            },
            "pointOfDistribution": [
                "Delivery",
                "Pickup"
            ],
            "templateValues": {
                "buyProducts": {
                    "products": [
                        {
                            "__typename": "Product",
                            "id": "100173",
                            "name": "20er Chicken McNuggets X-Burger",
                            "plu": 2881,
                            "nextStatusChangeWillBeAt": null as any,
                            "status": "active"
                        }
                    ],
                    "productSets": [] as any,
                    "excludedProductsIds": [] as any, 
                },
                "getProducts": {
                    "products": [
                        {
                            "__typename": "Product",
                            "id": "100173",
                            "name": "20er Chicken McNuggets X-Burger",
                            "plu": 2881,
                            "nextStatusChangeWillBeAt": null as any, 
                            "status": "active"
                        }
                    ],
                    "productSets": [] as any,
                    "excludedProductsIds": [] as any
                },
                "buy": "1",
                "discountType": "moneyOff",
                "discountValue": "3",
                "sameProduct": true
            },
            "term": {
                "id": 100008
            }
        }
    ],
    "templateType": "1",
    "term_content": {
        "en-US": "1 Valid 1x/day. Valid for product of equal or lesser value. Valid only at participating U.S. McDonald's. Prices may vary.",
        "es-ES": ""
    },
    "term_name": "T&C for tests"
  }
  
// State for Offer Generator
  export interface GenAIReducerState {
    prompt?: string;
    generatedOffer?: {title: string, img?: string, [key: string]: any };
  }
  
  export type GenAIActionType = 'SET_PROMPT' | 'SET_OFFER';
  
  export interface GenAIAction {
    type: GenAIActionType;
    payload: any;
  }
  
  export type GenAIReducer = (state: GenAIReducerState, action: GenAIAction) => GenAIReducerState;
  
  export const GenAIReducer: GenAIReducer = (state: GenAIReducerState, action: GenAIAction): GenAIReducerState => {
    switch (action.type) {
      case 'SET_PROMPT':
        return { ...state, prompt: action.payload };
      case 'SET_OFFER':
        return { ...state, generatedOffer: action.payload };
      default:
        return state;
    }
  };
  


  export const getRandomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  type genAICallResponse = {
    response?: {title: string, image?: string, description: string, generatedImage: {[key: string]: any }, [key: string]: any }
    error?: string; 
  }

  function extractImageName(url: string): string {
    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname;
    const imageName = path.substring(path.lastIndexOf('/') + 1);
    return imageName;
}
  
  export const makeGenAICall = async (body: any) => {
    let res : genAICallResponse = {response: null, error: null}; 

    console.log('making call');
    await generateOffer(body)
            .then(async (response: any) =>  {
              console.log('response retrieved');
              console.log(JSON.stringify(response)); 
                // let file = new File([], extractImageName(response.data.versions[0].translationsMap['en-US'].image.fileName) ); 
                // const images= [
                //     {
                //         name: extractImageName(response.data.versions[0].translationsMap['en-US'].image.fileName), 
                //         file: file, 
                //         description: '', 
                //         isBonusCampaign: false, 
                //         isArchive: false, 
                //     }
                // ]
                // let imageRes = await createNewImages(images); 
                // response.data = {
                //     ...response.data,
                //     versions: response.data.versions.map((version : any, index : any) =>
                //       index === 0
                //         ? {
                //             ...version,
                //             translationsMap: {
                //               ...version.translationsMap,
                //               'en-US': {
                //                 ...version.translationsMap['en-US'],
                //                 image: {
                //                   ...version.translationsMap['en-US'].image,
                //                   id: imageRes.data.createImages[0].id,
                //                 },
                //               },
                //             },
                //           }
                //         : version
                //     ),
                //   };
                // res.response = response; 
            })
            .catch((error: any)  => {
                res.error = error; 
               
        }); 

    return res; 
  }


  