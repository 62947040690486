import React, {  useEffect, useState } from 'react';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { defaultTheme } from 'styles/themes/defaultTheme';
import { ThemeProvider } from '@emotion/react';
import { navItems, promptItem, subNavItems } from '../genAI/genAI';
import NavSideBar from '../components/navSideBar/NavSideBar';
import { CenterGrid, RowGrid }  from '../genAI/genAI.style';
import LargePageTitle from '../components/LargePageTitle/LargePageTitle';
import InputWithIcon from '../components/inputWithIcon/InputWithIcon';
import OfferPreview from '../components/offerPreview/OfferPreview';
import OfferGeneratorResponse from '../components/offerGeneratorResponse/OfferGeneratorResponse';
import { ChatWrapper } from '../components/offerGeneratorResponse/OfferGeneratorResponse.style';
import { offerGenerator as offerGeneratorSlice, setSelectedOffer, setIsFromOfferDetails } from 'app/slices/offerGenerator';
import { store } from 'app/store';
import { useSelector } from "react-redux"


const OfferGenerator = () => {

    const { generatedOffers, selectedOffer, isFromOfferDetails  } = useSelector(offerGeneratorSlice); 

    const selectOfferResponse = (clickedOffer: promptItem) => {
        if (selectedOffer && clickedOffer.id === selectedOffer.id) {
            store.dispatch(setSelectedOffer(null)); 
        }
        else {
            store.dispatch(setSelectedOffer(clickedOffer)); 
        }
    }


    useEffect(() => {
        if (isFromOfferDetails) {
            store.dispatch(setSelectedOffer(generatedOffers[generatedOffers.length -1]))
            store.dispatch(setIsFromOfferDetails(false)); 
        }
        else {
            store.dispatch(setSelectedOffer(null))
        }

    }, []); 

    return (
        <PageContainer>
            <ThemeProvider theme={defaultTheme}>
            <RowGrid>
                <NavSideBar navBarItems={navItems} subNavItems={subNavItems}/>
                <CenterGrid>
                    <LargePageTitle text='AI Offer Generator'/>
                    <InputWithIcon placeholder='Type your offer details here...'  name='genAIPrompt'/>
                    <ChatWrapper>
                        {generatedOffers.length > 0 && generatedOffers.slice(0).reverse().map((promptItem : promptItem, index : number) => (
                        <OfferGeneratorResponse
                            key={index}
                            promptItem={promptItem}
                            onResponseItemClick={selectOfferResponse}
                            selectedOffer={selectedOffer}
                        />
                        ))}
                    </ChatWrapper>
                </CenterGrid>
                <OfferPreview fromPage='generator'  />
            </RowGrid>
            </ThemeProvider>
        </PageContainer>
)
   
}

export default OfferGenerator;