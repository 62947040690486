import styled from "@emotion/styled";
import typography from "styles/typography";


export const CardWrapper = styled.div`
    width: 220px; 
    height: 220px;
    border-radius: 2px; 
    padding: 20px; 
    background: #F5F5F5;
    text-align: center;
    box-sizing: border-box;
    font-family: ${typography.primaryFont}; 
    position: sticky; 
    border-radius: 13px;

`

export const CardTitle = styled.p`
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 3px; 

`

export const SubGrid = styled.div`
    display: flex; 
    flex-direction: column; 
    gap: 20px;
    justify-content: space-evenly; 

`

export const SubRow = styled.div`
    display: flex; 
    flex-direction: row; 
    gap: 20px; 
`

type CardItemsProps = {
    percentage?: number; 
}
export const CardItems = styled.div<CardItemsProps>`
    display: flex; 
    flex-direction: column; 
    gap: 10px; 
    position: relative; 
    top: ${({percentage}) => percentage ? '10%;': '20%;'}
    bottom: 50%; 
`

export const PieChartWrapper = styled.div`
    width: 100%; 
    height: 100%; 
    display: flex;
    border-radius: 13px;
    background-color: #F5F5F5; 
    justify-content: center; 
    padding: 36px; 
    flex-direction: column; 
    align-items: center; 

`

export const CenterGridVertical = styled.div`
    padding: 60px 40px 0px 40px; 
    display: flex; 
    flex-direction: column; 
    overflow-y: scroll;
    width: 100%; 

`

export const HorizontalSection = styled.div`
    display: flex; 
    flex-direction: row;
    gap: 60px; 

`

export const LineChartWrapper = styled.div`
    display: flex;  
    width: 100%; 
    margin: 60px 0px 60px 0px; 
    justify-content: center; 
    border-radius: 13px;
    background: #F5F5F5;
    padding: 40px; 
    flex-direction: column; 
    gap: 20px; 

`

export const PieChartLabel = styled.text`
    color: #292929;
    text-align: center;
    font-family: ${typography.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const LineChartTitle = styled.text`
    color: #292929;
    font-family: ${typography.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

`