
import styled from "@emotion/styled";


export const StyledInput = styled.input`
    border-bottom: none !important; 
    background: #FFF;
    height: 26px;
    width: 100%; 
    font-family: Speedee; 
    border: none; 
    border-radius: 60px;
    margin: 15px 0px 15px 0px; 
    outline none; 
    outline-color: transparent; 
    font-size: 20px;
    overflow: hidden; 

`

export const StyledInputWithIconWrapper = styled.div`
    border-radius: 60px;
    border: 3px solid #DB0006;
    display: flex; 
    flex-direction: row; 
    background: #FFF;
    align-items: center; 
    padding: 0px 15px 0px 15px; 

`

export const IconWrapper = styled.div`

    :hover {
        cursor: pointer; 
    }
`